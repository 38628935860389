import React, { Fragment, Component } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { graphql } from "gatsby"
import { validate } from "../components/helpers"
import {
  Container,
  Section,
  SectionTitle,
  Banner,
  Success,
} from "../styles/common"
import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from "axios"
import Loader from "../components/loader"
import {
  Offices,
  OfficeItem,
  OfficeIconholder,
  OfficeInner,
  ContactInfo,
  ContactForm,
} from "../styles/contact"
import imgPlace from "../images/contactUs.png"
import CommunityDriven from "../components/Components/CommunityDriven"

class ContactPage extends Component {
  state = {
    success: false,
    errors: {},
    loading: false,
    submitted: false,
    name: "",
    email: "",
    enterprise: "",
    subject: "",
    message: "",
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.validateForm()
  }

  validateForm = () => {
    let validated = true
    let errors = {}
    let { email, name, message } = this.state
    if (!validate(email)) {
      validated = false
      errors.email = "Email not valid"
    }
    if (!name) {
      validated = false
      errors.name = "Name is required"
    }
    if (!message) {
      validated = false
      errors.message = "Message is required"
    }

    if (!validated) {
      return this.setState({
        errors,
        submitted: true,
      })
    } else {
      this.setState(
        {
          errors: {},
          submitted: false,
          loading: true,
        },
        () => this.submmitMail()
      )
    }
  }

  submmitMail = () => {
    axios({
      method: "post",
      url: "https://x-hub.io/contactform/contact.php",
      data: {
        email: this.state.email,
        name: this.state.name,
        subject: this.state.subject,
        message: this.state.message,
        enterprise: this.state.enterprise,
      },
      config: {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      },
    })
      .then(() => {
        this.setState({
          loading: false,
          success: true,
          name: "",
          email: "",
          enterprise: "",
          subject: "",
          message: "",
        })
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    const {
      name,
      email,
      enterprise,
      subject,
      message,
      submitted,
      errors,
      loading,
      success,
    } = this.state
    return (
      <Layout location="contact">
        <Fragment>
          <SEO
            title="xHub | Contact us "
            keywords={[
              "xhub",
              "devoxx",
              "devoxxmorocco",
              "devoxx4kids",
              "morocco",
              "casablanca",
              "x-hub.io",
              "devc",
              "Application & Technologies",
            ]}
          />
          {/* <Banner contact overlay>
            <Container flex flexCenter>
              <ScrollAnimation
                style={{ width: "100%" }}
                duration={0.4}
                animateOnce={true}
                animateIn="slideInLeft"
              >
                <SectionTitle color="white" transform="uppercase">
                  . Let's Get In Touch
                </SectionTitle>
              </ScrollAnimation>
            </Container>
          </Banner> */}
          <Section bgcolor="#f6f6f6">
            <Container>
          <CommunityDriven title="Let's get in touch" description="Have something in mind? Let's make something amazing together!" img={imgPlace} />
              </Container>
              </Section>
          <Section padding="60px 0 60px" bgcolor="white">
            <Container flex>
              <ContactInfo>
                <h4>Get in touch with us:</h4>
                <ul>
                  <li className="email">
                    <span className="icon-email">&nbsp;</span>
                    <a href="mailto:info@x-hub.io" title="Email">
                      <span>info@x-hub.io</span>
                    </a>
                  </li>
                  <li>
                    <span className="icon-phone">&nbsp;</span>
                    <a href="tel:+212 6 60 43 50 14" title="Phone">
                      <span>+212 6 60 43 50 14</span>
                    </a>
                  </li>
                </ul>
                <h4 style={{paddingTop: "20px"}}>For more information:</h4>
                <ul>
                  <li className="email">
                    <span className="icon-email">&nbsp;</span>
                    <a href="mailto:info@x-hub.io" title="Email">
                      <span>info@x-hub.io</span>
                    </a>
                  </li>
                  <li>
                    <span className="icon-phone">&nbsp;</span>
                    <a href="tel:+212 6 60 43 50 14" title="Phone">
                      <span>+212 6 60 43 50 14</span>
                    </a>
                  </li>
                </ul>
              </ContactInfo>

              <ContactForm
                name="contactform"
                onSubmit={this.handleSubmit}
                // action="https://formspree.io/m.mouhsni@gmail.com"
                // method="POST"
                // action={withPrefix("/contact/contact.php")}
              >
                {loading && <Loader absolute />}

                {success && (
                  <Success>
                    Thank you! Your message has been sent successfully
                  </Success>
                )}

                <div className="form-container">
                  <div className="form-item">
                    {submitted && errors.name && (
                      <span className="error">{errors.name}</span>
                    )}
                    <input
                      className="required"
                      onChange={this.handleInputChange}
                      value={name}
                      id="id_name"
                      name="name"
                      placeholder="Name*"
                      type="text"
                    />
                  </div>
                  <div className="form-item">
                    {submitted && errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                    <input
                      onChange={this.handleInputChange}
                      value={email}
                      className="email required"
                      id="id_email"
                      name="email"
                      placeholder="Email*"
                      type="text"
                    />
                  </div>
                  <div className="form-item">
                    <input
                      onChange={this.handleInputChange}
                      value={enterprise}
                      id="id_enterprise"
                      name="enterprise"
                      placeholder="Enterprise"
                      type="text"
                    />
                  </div>
                  <div className="form-item">
                    <input
                      onChange={this.handleInputChange}
                      value={subject}
                      id="id_subject"
                      name="subject"
                      placeholder="Subject"
                      type="text"
                    />
                  </div>
                  <div className="message">
                    {submitted && errors.message && (
                      <span className="error">{errors.message}</span>
                    )}
                    <textarea
                      onChange={this.handleInputChange}
                      value={message}
                      className="required"
                      cols="40"
                      id="id_message"
                      name="message"
                      placeholder="Message*"
                      rows="10"
                      resize="none"
                    />
                  </div>
                </div>
                <div className="button">
                  <input type="submit" className="submit" value="Submit" />
                </div>
              </ContactForm>
            </Container>
          </Section>

          <Section bgcolor="white" padding="50px 0 0">
            <Container>
              <ScrollAnimation animateOnce={true} animateIn="slideInUp">
                <SectionTitle
                  transform="uppercase"
                  space="30px 0 0"
                  lightChild
                  color="black"
                >
                  Our <div>offices</div>
                  <span>
                    Come to discover our offices & drink a cup of tea No need to
                    Suite & Tie, casual is original.
                  </span>
                </SectionTitle>
              </ScrollAnimation>
            </Container>
            <Offices>
              <OfficeItem>
                <a href="https://goo.gl/maps/QptncmED5dSCTmFc9" target="_blank" style={{textDecoration: "none"}}>
                  <OfficeInner>
                    <OfficeIconholder size="40px">
                      <span className="icon-morocco3" />
                    </OfficeIconholder>
                    <h3 className="city">Casablanca</h3>
                    <p className="offices__address">
                      Bureau N°327, Technopark, boulevard dammam - Casablanca
                    </p>
                  </OfficeInner>
                </a>
              </OfficeItem>
              <OfficeItem>
              <a href="https://goo.gl/maps/unxaNBs58ksnwJ2k9" target="_blank" style={{textDecoration: "none"}}>
                <OfficeInner>
                  <OfficeIconholder size="40px">
                    <span className="icon-morocco3" />
                  </OfficeIconholder>
                  <h3 className="city">Agadir</h3>
                  <p className="offices__address">
                  Bureau N°A123, Technopark, route national 1, Agadir 
                  </p>
                </OfficeInner>
                </a>
              </OfficeItem>
              <OfficeItem>
              <a href="https://goo.gl/maps/JB2H11mvaFyGm5D58" target="_blank" style={{textDecoration: "none"}}>
                <OfficeInner>
                  <OfficeIconholder size="40px">
                    <span className="icon-canada_leaf" />
                  </OfficeIconholder>
                  <h3 className="city">Quebec</h3>
                  <p className="offices__address">
                    525 Saint Paul 315 Quebec Canada
                  </p>
                </OfficeInner>
                </a>
              </OfficeItem>
              <OfficeItem>
              <a href="https://goo.gl/maps/cKBvEf9co38pVh1N8" target="_blank" style={{textDecoration: "none"}}>
                <OfficeInner>
                  <OfficeIconholder size="40px">
                    <span className="icon-bull" />
                  </OfficeIconholder>
                  <h3 className="city">Valencia</h3>
                  <p className="offices__address">
                    Avenida Hispanitat, 4, local 1. Alzira - Valencia
                  </p>
                </OfficeInner>
                </a>
              </OfficeItem>
            </Offices>
          </Section>
        </Fragment>
      </Layout>
    )
  }
}
export const query = graphql`
  query ContactPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
export default ContactPage
